import { useEffect, useState } from 'react';
import bot from './Group 1000001356.png'
import './bot-message.css'
function BotMessage ({text}) {
    const [currentTime, setCurrentTime] = useState('');
    const updateCurrentTime = () => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = String(minutes).padStart(2, '0');
  
        setCurrentTime(
          `${formattedHours}:${formattedMinutes} ${ampm}`
        );
      };
    useEffect(() => {
        updateCurrentTime()
    }, [text]);
    if(text === "Loading" || text === "loading") {
        return(
            <div className="bot-message-container" style={{position : "relative"}}>
                <img src={bot} alt="" />
                <div className='chat-text'>
                    <div className="dots-loading">
                        <span className="dot-load" id="do1L1"></span>
                        <span className="dot-load" id="do1L2"></span>
                        <span className="dot-load" id="do1L3"></span>
                    </div>
                </div>
            </div>
            )
    }else {
        let color = 'white';
        if(text.indexOf('$Correction&') !== -1 ){
            color = "#6fff5d"
            text = text.slice(12)
        }
        return (
            <div className="bot-message-container" style={{position : "relative"}}>
            <span className='time-bot' style={{position : "absolute" , color : "white" , fontFamily : "Gilroy-Light, sans-serif"}}>{currentTime}</span>
            <img src={bot} alt="" />
            <div className='chat-text' style={{color : color}}>{text}</div>
        </div>
    )
}
}

export default BotMessage ;