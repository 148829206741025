export const Dot = ({ active, onClick }) => {
    return(
        <span className={`dot ${active ? 'active' : ''}`} onClick={onClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                            <g filter="url(#filter0_i_1987_25167)">
                                <circle cx="17" cy="17" r="17" fill="white" fillOpacity="0.57"/>
                            </g>
                            <defs>
                                <filter id="filter0_i_1987_25167" x="0" y="0" width="34" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dy="4"/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.28 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1987_25167"/>
                                </filter>
                            </defs>
                        </svg>
                        </span>
        )
}