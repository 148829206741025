import './landing.css';
import LosyroLogo from '../imgs/losyro_logo.svg';
import Beta from '../imgs/Beta.svg';
import BotChat from '../imgs/botChat.png';
import { Dot } from './Dot';
import BotMessage from '../botMessage/BotMessage';
import UserMessage from '../userMessage/UserMessage';
import Robot from '../imgs/r3ob8 1.svg';
import Robot2 from '../imgs/rob1@2x 1.svg';
import { useEffect, useRef, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import Vector from './Vector.svg'
import axios from 'axios';
import { BaseURL } from '../constants/URLs';

function Landing() {
    const [showCanvas , setShowCanvas] = useState(0);
    const [showConfirm , setShowConfirm] = useState(0);
    const [showDenied , setShowDenied] = useState(0);
    const [showThx , setShowthx] = useState(0);
    const [haveSay , setHaveSay] = useState(0);
    const [loadBot , setLoadBot] = useState(0);
    const [rate ] = useState(0);
    const [messages , setMessages] = useState([])
    const [userMessage, setUserMessage] = useState('');
    const chatContainerRef = useRef(null);
    const [token , setToken] = useState(0)
    const GetToken = async () => {
        await axios.get(`${BaseURL}/create_user`).then(res => {setToken(res.data.user_id)}).catch(error => {console.log(error)})
    }
    const GetBotMessages = async() => {
        setTimeout(() => {
            fetch(`${BaseURL}/chat?user_id=` + String(token) + '&msg=' + userMessage, {
                method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            setMessages((prevMessages) => prevMessages.slice(0, -1));
            data.forEach((e, index) => {
                      setTimeout(() => {
                        addBotMessage(e);
                      }, 1500 * (index + 1));
                    });
        })
        .catch(error => {
            console.error('Error:', error);
        }).finally(()=>{
                setLoadBot(0)
            })
            }, 2500);
    }
    useEffect(()=> {
        GetToken()
    },[])
    useEffect(() => {
        scrollToBottom();
      }, [messages]);
      const addWelcomeMessage = () => {
        if(messages.length === 0) {
                        setTimeout(() => {
                            setMessages((prevMessages) => [
                                ...prevMessages,
                                { text: "Hello my friend, my name is BOT 😊 ", type: 'bot' }
                            ]);
                            setTimeout(() => {
                                setMessages((prevMessages) => [
                                    ...prevMessages,
                                    { text: "I'm here to assist you in English practicing through a friendly conversation.⚙️🤖💬", type: 'bot' }
                                ]);
                            }, 1000);
                        }, 2500);
                    }
      };
      
      const addUserMessage = () => {
        if (userMessage.trim() !== '') {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: userMessage, type: 'user' }
          ]);
        }
        setUserMessage('');
        loadMessage();
      };
      const addBotMessage = (e) => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: e, type: 'bot' }
          ]);
        // loadMessage();
      };
      
      const loadMessage = () => {
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: "Loading", type: 'bot' }
          ]);
        }, 1000);
      };
      
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      };
        const [rating, setRating] = useState(0);
        const handleRatingClick = (selectedRating) => {
            setRating(selectedRating);
            setTimeout(() => {
              if(messages.length > 3){
                setShowConfirm(1);
              }else {
                setShowDenied(1);
                setRating(0)
              }
            }, 1500);
          };
        const Rateing = async () => {
            fetch(`${BaseURL}/rate?user_id=` + String(token) + '&rate=' + rate + 1, {
                method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res =>{
            return res.json()
        }).then(data =>{
            console.log(data)
        }).catch(error => {
            console.log(error)
        })
        }
        const openLinkInNewTab = () => {
            const url = 'https://docs.google.com/forms/d/e/1FAIpQLScAyN69WA-tYqVRrUnQTNudo65Mvy5MBKnf5x79ePJBHx4GGw/viewform';
            window.open(url, '_blank');
          };
          console.log(rating)
    return(
        <div className='landing-container' style={{position : "relative"}}>
            <div className="landing">
                <div className="logo"><img className='brand' src={LosyroLogo} alt="" /></div>
                <div className="title">
                    Give <span className="bot">BOT</span><img className='beta' src={Beta} alt="" /><span className='try'>a Try or More!</span>
                </div>
                <div className="description">
                    <span className='think-def'>Think Diff </span>
                    and feel free to voice your thoughts
                </div>
                <div className="rate-us">
                    <p>Rate Us :</p>
                    <div className="dots">
                    {[1, 2, 3, 4, 5 , 6].map((index) => (
                        <Dot
                            key={index}
                            active={index <= rating}
                            onClick={() => handleRatingClick(index - 1)}
                        />
                        ))}
                        <div className="dots-fill">
                        {[2, 3, 4, 5, 6 , 7].map((index) => (
                            <img
                            key={index}
                            className={`img-fill-rate ${index <= rating ? 'visible' : ''}`}
                            id={`fill-rate-${index - 1}`}
                            src={Vector}
                            alt=""
                            onClick={() => {handleRatingClick(index - 1)}}
                            />
                        ))}
                        </div>
                    </div>
                </div>
                    <span className={haveSay ? "have-say-2" : "have-say-2 d-none"} onClick={()=>{setTimeout(()=>{setShowthx(0);openLinkInNewTab()},[1000])}}>Have Your Say</span>
            </div>
                <div className={showConfirm ? "confirm-form-overlay" : "confirm-form-overlay d-none"} style={{color : "white"}}>
                    <div className="confirm-form">
                        <div className="exit-icon-confirm" onClick={()=>{setShowConfirm(0)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M8.47266 5.1269L12.2543 1.34529C12.5448 1.05472 12.8568 0.845348 13.19 0.717158C13.5063 0.588968 13.8182 0.533419 14.1258 0.550511C14.4249 0.559057 14.7155 0.627425 14.9975 0.755615C15.271 0.875259 15.5103 1.03763 15.7154 1.24274C15.9205 1.44784 16.0829 1.68713 16.2025 1.9606C16.3222 2.23407 16.3863 2.52036 16.3948 2.81947C16.3948 3.11004 16.3307 3.41342 16.2025 3.72962C16.0658 4.05437 15.8521 4.36203 15.5616 4.65259L11.78 8.4342L15.6128 12.2671C15.9034 12.5576 16.1128 12.861 16.241 13.1772C16.3521 13.4934 16.4033 13.8096 16.3948 14.1258C16.3777 14.4335 16.3093 14.7241 16.1897 14.9975C16.0701 15.271 15.9077 15.5103 15.7026 15.7154C15.4975 15.9205 15.2582 16.0829 14.9847 16.2025C14.7112 16.3222 14.4249 16.3863 14.1258 16.3948C13.8096 16.4033 13.4977 16.3478 13.19 16.2282C12.8738 16.1 12.5705 15.8906 12.2799 15.6L8.44702 11.7671L4.63977 15.5744C4.34066 15.8735 4.03301 16.0871 3.71681 16.2153C3.4006 16.3435 3.09722 16.4076 2.80666 16.4076C2.50755 16.3991 2.22553 16.3307 1.9606 16.2025C1.68713 16.0829 1.44784 15.9205 1.24274 15.7154C1.03763 15.5103 0.875259 15.271 0.755616 14.9975C0.618879 14.7241 0.546238 14.4378 0.537693 14.1387C0.520601 13.831 0.57615 13.5191 0.704339 13.2029C0.823984 12.8781 1.03336 12.5662 1.33247 12.2671L5.13971 8.45984L1.35811 4.67823C1.059 4.37912 0.845349 4.07146 0.717159 3.75526C0.588969 3.43906 0.53342 3.12713 0.550512 2.81947C0.559058 2.52036 0.623153 2.23407 0.742797 1.9606C0.862441 1.68713 1.02482 1.44784 1.22992 1.24274C1.43502 1.03763 1.67431 0.875259 1.94778 0.755615C2.22126 0.635971 2.51182 0.567603 2.81948 0.550511C3.12713 0.533419 3.44333 0.584695 3.76808 0.704339C4.08428 0.832529 4.39194 1.04618 4.69105 1.34529L8.47266 5.1269Z" fill="#01B9FD"/>
                            </svg>
                        </div>
                        <h3>Confirm Your FeedBack</h3>
                        <div>
                        <p>Are You Sure Do you want to Confirm Your Feedback ?</p>
                        </div>
                        <div className="btns-form">
                        <span className="cancel" onClick={()=>{setShowConfirm(0)}}>Cancel</span>
                        <span className="confirm" onClick={() => {setShowthx(1); setShowConfirm(0);Rateing()}}>Confirm</span>
                    </div>
                </div>
                </div>
                <div className={showDenied ? "confirm-form-overlay" : "confirm-form-overlay d-none"} style={{color : "white"}}>
                    <div className="confirm-form">
                        <div className="exit-icon-confirm" onClick={()=>{setShowDenied(0)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M8.47266 5.1269L12.2543 1.34529C12.5448 1.05472 12.8568 0.845348 13.19 0.717158C13.5063 0.588968 13.8182 0.533419 14.1258 0.550511C14.4249 0.559057 14.7155 0.627425 14.9975 0.755615C15.271 0.875259 15.5103 1.03763 15.7154 1.24274C15.9205 1.44784 16.0829 1.68713 16.2025 1.9606C16.3222 2.23407 16.3863 2.52036 16.3948 2.81947C16.3948 3.11004 16.3307 3.41342 16.2025 3.72962C16.0658 4.05437 15.8521 4.36203 15.5616 4.65259L11.78 8.4342L15.6128 12.2671C15.9034 12.5576 16.1128 12.861 16.241 13.1772C16.3521 13.4934 16.4033 13.8096 16.3948 14.1258C16.3777 14.4335 16.3093 14.7241 16.1897 14.9975C16.0701 15.271 15.9077 15.5103 15.7026 15.7154C15.4975 15.9205 15.2582 16.0829 14.9847 16.2025C14.7112 16.3222 14.4249 16.3863 14.1258 16.3948C13.8096 16.4033 13.4977 16.3478 13.19 16.2282C12.8738 16.1 12.5705 15.8906 12.2799 15.6L8.44702 11.7671L4.63977 15.5744C4.34066 15.8735 4.03301 16.0871 3.71681 16.2153C3.4006 16.3435 3.09722 16.4076 2.80666 16.4076C2.50755 16.3991 2.22553 16.3307 1.9606 16.2025C1.68713 16.0829 1.44784 15.9205 1.24274 15.7154C1.03763 15.5103 0.875259 15.271 0.755616 14.9975C0.618879 14.7241 0.546238 14.4378 0.537693 14.1387C0.520601 13.831 0.57615 13.5191 0.704339 13.2029C0.823984 12.8781 1.03336 12.5662 1.33247 12.2671L5.13971 8.45984L1.35811 4.67823C1.059 4.37912 0.845349 4.07146 0.717159 3.75526C0.588969 3.43906 0.53342 3.12713 0.550512 2.81947C0.559058 2.52036 0.623153 2.23407 0.742797 1.9606C0.862441 1.68713 1.02482 1.44784 1.22992 1.24274C1.43502 1.03763 1.67431 0.875259 1.94778 0.755615C2.22126 0.635971 2.51182 0.567603 2.81948 0.550511C3.12713 0.533419 3.44333 0.584695 3.76808 0.704339C4.08428 0.832529 4.39194 1.04618 4.69105 1.34529L8.47266 5.1269Z" fill="#01B9FD"/>
                            </svg>
                        </div>
                        <h3>Feedback Confirmation Denied</h3>
                        <div>
                        <p style={{textAlign : "center" , lineHeight : "40px"}}>We apologize, but your feedback confirmation has been denied because you did't use the BOT <br/>. thank you .</p>
                        </div>
                </div>
                </div>
                <div className={showThx ? "thx-form-overlay"  : "thx-form-overlay d-none"}>
                <div className="thx-form">
                        <div className="exit-icon-confirm" onClick={()=>{setShowthx(0);setHaveSay(1)} }>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M8.47266 5.1269L12.2543 1.34529C12.5448 1.05472 12.8568 0.845348 13.19 0.717158C13.5063 0.588968 13.8182 0.533419 14.1258 0.550511C14.4249 0.559057 14.7155 0.627425 14.9975 0.755615C15.271 0.875259 15.5103 1.03763 15.7154 1.24274C15.9205 1.44784 16.0829 1.68713 16.2025 1.9606C16.3222 2.23407 16.3863 2.52036 16.3948 2.81947C16.3948 3.11004 16.3307 3.41342 16.2025 3.72962C16.0658 4.05437 15.8521 4.36203 15.5616 4.65259L11.78 8.4342L15.6128 12.2671C15.9034 12.5576 16.1128 12.861 16.241 13.1772C16.3521 13.4934 16.4033 13.8096 16.3948 14.1258C16.3777 14.4335 16.3093 14.7241 16.1897 14.9975C16.0701 15.271 15.9077 15.5103 15.7026 15.7154C15.4975 15.9205 15.2582 16.0829 14.9847 16.2025C14.7112 16.3222 14.4249 16.3863 14.1258 16.3948C13.8096 16.4033 13.4977 16.3478 13.19 16.2282C12.8738 16.1 12.5705 15.8906 12.2799 15.6L8.44702 11.7671L4.63977 15.5744C4.34066 15.8735 4.03301 16.0871 3.71681 16.2153C3.4006 16.3435 3.09722 16.4076 2.80666 16.4076C2.50755 16.3991 2.22553 16.3307 1.9606 16.2025C1.68713 16.0829 1.44784 15.9205 1.24274 15.7154C1.03763 15.5103 0.875259 15.271 0.755616 14.9975C0.618879 14.7241 0.546238 14.4378 0.537693 14.1387C0.520601 13.831 0.57615 13.5191 0.704339 13.2029C0.823984 12.8781 1.03336 12.5662 1.33247 12.2671L5.13971 8.45984L1.35811 4.67823C1.059 4.37912 0.845349 4.07146 0.717159 3.75526C0.588969 3.43906 0.53342 3.12713 0.550512 2.81947C0.559058 2.52036 0.623153 2.23407 0.742797 1.9606C0.862441 1.68713 1.02482 1.44784 1.22992 1.24274C1.43502 1.03763 1.67431 0.875259 1.94778 0.755615C2.22126 0.635971 2.51182 0.567603 2.81948 0.550511C3.12713 0.533419 3.44333 0.584695 3.76808 0.704339C4.08428 0.832529 4.39194 1.04618 4.69105 1.34529L8.47266 5.1269Z" fill="#01B9FD"/>
                            </svg>
                        </div>
                    <div className="true-sign">
                        <svg className='true' xmlns="http://www.w3.org/2000/svg" width="49" height="42" viewBox="0 0 49 42" fill="none">
                            <path d="M42.307 3.68254C43.0367 3.68254 43.7731 3.93074 44.3783 4.43906C45.7371 5.5772 45.9234 7.5926 44.7957 8.93943L22.0628 36.0572C21.4419 36.7973 19.6531 38.7792 19.6531 38.7792C19.6531 38.7792 18.1229 37.2647 17.2747 36.3129L4.89979 24.5692C3.63047 23.3543 3.5932 21.3315 4.8178 20.0495C5.45283 19.3847 6.30177 19.0492 7.14923 19.0492C7.9378 19.0492 8.72413 19.3392 9.33606 19.9243L19.2946 29.361L39.8742 4.81322C40.4988 4.06713 41.3977 3.68254 42.307 3.68254ZM42.307 0.701172C40.4787 0.701172 38.7584 1.50241 37.5882 2.89844L19.0456 25.0179L11.3865 17.7606C10.2498 16.6724 8.74128 16.0679 7.14997 16.0679C5.46475 16.0679 3.82873 16.7678 2.66227 17.9901C0.305499 20.4587 0.385251 24.3762 2.83817 26.7233L15.1341 38.3931C16.0434 39.4001 17.4916 40.8348 17.5542 40.8974C18.1132 41.4505 18.8675 41.7598 19.6516 41.7598C19.6792 41.7598 19.7067 41.7591 19.7351 41.7583C20.5497 41.736 21.3189 41.3804 21.8638 40.7767C21.939 40.694 23.6973 38.7449 24.345 37.972L47.0787 10.8535C48.1393 9.58788 48.6394 7.98316 48.4866 6.33521C48.3353 4.69695 47.5557 3.21148 46.2916 2.15235C45.1773 1.21695 43.7612 0.701172 42.307 0.701172Z" fill="url(#paint0_linear_1987_28120)"/>
                            <path d="M39.2564 10.6194C39.0887 10.6194 38.921 10.5635 38.7816 10.448C38.4641 10.1849 38.4209 9.71603 38.6832 9.39926L40.2209 7.54485C40.4832 7.22808 40.9528 7.18336 41.2696 7.44647C41.5871 7.70957 41.6303 8.17839 41.3679 8.49516L39.831 10.3488C39.6835 10.5277 39.471 10.6194 39.2564 10.6194Z" fill="url(#paint1_linear_1987_28120)"/>
                            <path d="M30.167 21.1498C29.9911 21.1498 29.8137 21.088 29.6721 20.9613C29.3643 20.6885 29.3367 20.2167 29.6102 19.9096L36.9399 11.6639C37.2127 11.3553 37.6845 11.3277 37.9916 11.602C38.2994 11.8748 38.327 12.3466 38.0535 12.6537L30.7238 20.8994C30.577 21.0649 30.372 21.1498 30.167 21.1498Z" fill="url(#paint2_linear_1987_28120)"/>
                            <defs>
                                <linearGradient id="paint0_linear_1987_28120" x1="-11.4948" y1="-43.5989" x2="88.7117" y2="-16.4034" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#00FEFC"/>
                                <stop offset="1" stopColor="#0002FE"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_1987_28120" x1="37.7199" y1="3.66638" x2="44.2731" y2="5.0561" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#00FEFC"/>
                                <stop offset="1" stopColor="#0002FE"/>
                                </linearGradient>
                                <linearGradient id="paint2_linear_1987_28120" x1="27.1153" y1="0.907647" x2="46.2042" y2="4.95829" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#00FEFC"/>
                                <stop offset="1" stopColor="#0002FE"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <svg className='circle' xmlns="http://www.w3.org/2000/svg" width="75" height="76" viewBox="0 0 75 76" fill="none">
                            <circle cx="37.5" cy="38" r="35.5" stroke="url(#paint0_linear_1987_28127)" strokeWidth="4"/>
                            <defs>
                                <linearGradient id="paint0_linear_1987_28127" x1="-19.6141" y1="-80.421" x2="141.192" y2="-42.7513" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#00FEFC"/>
                                <stop offset="1" stopColor="#0002FE"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <h3>Thank You </h3>
                    <p>Your feedback has been confirmed You Can Share us your ideas By clicking Have Your Say button</p>
                    <span className="have-say" onClick={()=>{setTimeout(()=>{setShowthx(0);openLinkInNewTab()},[1000])}}>Have Your Say</span>
                </div>
                </div>
                    <div className={showCanvas ? "click-me op-0 d-none-2"  : "click-me op-100 d-2 d-none-2"}>
                        Click Here !
                    </div>
            <div className={showCanvas ? "canvas-bot d-none-canvas-responsive"  : "canvas-bot hidden-canvas d-none-canvas-responsive"} style={showCanvas ? {transitionDelay : "1s" , transitionDuration : "1s"} : {}}>
                <div className="toggle-btn d-none-2" onClick={()=> {}}>
                    <div className={showCanvas ? "top-sec op-100"  : "op-0"}>
                        <div className="user">
                            <img className='chat-bot-user' src={BotChat} alt="" />
                        </div>
                        <h1 className='header'>bot Chatbot</h1>
                    </div>
                    <div className={showCanvas ? "bottom-sec op-100" : "op-0"}>
                    </div>
                    <div className="btn-togg" onClick={()=>{setShowCanvas(!showCanvas);addWelcomeMessage()}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="203" viewBox="0 0 38 203" fill="none">
                        <path d="M0 29.736C0 24.9217 2.47364 20.4451 6.5496 17.8831L35 0L38 203L6.52409 183.12C2.46271 180.555 0 176.087 0 171.284V29.736Z" fill="url(#paint0_linear_1987_24377)"/>
                        <path className={showCanvas ? "op-100" : "op-0"} fillRule="evenodd" clipRule="evenodd" d="M34.0264 1.79313L7.08177 18.7297C3.29695 21.1088 1 25.2656 1 29.736V171.284C1 175.744 3.28681 179.893 7.05808 182.275L36.9728 201.169L34.0264 1.79313ZM6.5496 17.8831C2.47364 20.4451 0 24.9217 0 29.736V171.284C0 176.087 2.46271 180.555 6.52409 183.12L38 203L35 0L6.5496 17.8831Z" fill="url(#paint1_linear_1987_24377)"/>
                        <path className={showCanvas ? "op-100" : "op-0"} d="M16.6592 92.0001C16.8727 91.9994 17.0836 92.0418 17.2765 92.1241C17.4693 92.2065 17.6392 92.3266 17.7737 92.4758L24.675 100.189C24.8851 100.419 25 100.708 25 101.006C25 101.303 24.8851 101.592 24.675 101.822L17.5308 109.536C17.2883 109.798 16.9398 109.963 16.562 109.995C16.1841 110.026 15.808 109.921 15.5162 109.703C15.2244 109.484 15.0409 109.171 15.006 108.831C14.9712 108.491 15.0879 108.153 15.3304 107.89L21.7173 100.999L15.5447 94.1085C15.37 93.9198 15.259 93.69 15.2249 93.4463C15.1908 93.2026 15.235 92.9552 15.3522 92.7334C15.4695 92.5116 15.6549 92.3247 15.8865 92.1947C16.1181 92.0648 16.3863 91.9972 16.6592 92.0001Z" fill="black"/>
                        <path className={showCanvas ? "op-100" : "op-0"} fillRule="evenodd" clipRule="evenodd" d="M16.6487 93C16.5456 92.9989 16.4505 93.0249 16.3758 93.0668C16.3016 93.1084 16.2583 93.1592 16.2363 93.2008C16.2152 93.2406 16.2109 93.2764 16.2153 93.3076C16.2197 93.3396 16.2359 93.383 16.2785 93.4291L16.2841 93.4351L23.0701 101.011L16.065 108.569C16.0651 108.568 16.0648 108.569 16.065 108.569C16.0073 108.631 15.9968 108.689 16.0008 108.729C16.0051 108.771 16.0305 108.839 16.1151 108.902C16.2027 108.967 16.3341 109.01 16.4793 108.998C16.6243 108.986 16.7345 108.924 16.7963 108.857L16.7971 108.856L23.9382 101.146C23.9873 101.091 24 101.041 24 101.006C24 100.97 23.987 100.919 23.9367 100.864L23.9297 100.856L17.031 93.1454C17.0307 93.1451 17.0305 93.1448 17.0302 93.1445C16.9972 93.1082 16.9488 93.0716 16.8839 93.0439C16.8184 93.0159 16.7423 92.9998 16.6623 93.0001L16.6487 93ZM24.675 101.822L17.5308 109.536C17.2883 109.798 16.9398 109.963 16.562 109.995C16.1841 110.026 15.808 109.921 15.5162 109.703C15.2244 109.484 15.0409 109.171 15.006 108.831C14.9712 108.491 15.0879 108.153 15.3304 107.89L21.7173 100.999L15.5447 94.1085C15.37 93.9198 15.259 93.69 15.2249 93.4463C15.1908 93.2026 15.235 92.9552 15.3522 92.7334C15.4695 92.5116 15.6549 92.3247 15.8865 92.1947C16.1181 92.0648 16.3863 91.9972 16.6592 92.0001C16.8727 91.9994 17.0836 92.0418 17.2765 92.1241C17.4693 92.2065 17.6392 92.3266 17.7737 92.4758L24.675 100.189C24.8851 100.419 25 100.708 25 101.006C25 101.303 24.8851 101.592 24.675 101.822Z" fill="black"/>
                        <path className={!showCanvas ? "op-100" : "op-0"} d="M0 29.736C0 24.9217 2.47364 20.4451 6.5496 17.8831L38 0V203L6.52409 183.12C2.46271 180.555 0 176.087 0 171.284V29.736Z" fill="url(#paint0_linear_1987_25200)"/>
                        <path className={!showCanvas ? "op-100" : "op-0"} d="M23.3408 92.0001C23.1273 91.9994 22.9164 92.0418 22.7235 92.1241C22.5307 92.2065 22.3608 92.3266 22.2263 92.4758L15.325 100.189C15.1149 100.419 15 100.708 15 101.006C15 101.303 15.1149 101.592 15.325 101.822L22.4692 109.536C22.7117 109.798 23.0602 109.963 23.438 109.995C23.8159 110.026 24.192 109.921 24.4838 109.703C24.7756 109.484 24.9591 109.171 24.994 108.831C25.0288 108.491 24.9121 108.153 24.6696 107.89L18.2827 100.999L24.4553 94.1085C24.63 93.9198 24.741 93.69 24.7751 93.4463C24.8092 93.2026 24.765 92.9552 24.6478 92.7334C24.5305 92.5116 24.3451 92.3247 24.1135 92.1947C23.8819 92.0648 23.6137 91.9972 23.3408 92.0001Z" fill="black"/>
                        <path className={!showCanvas ? "op-100" : "op-0"} fillRule="evenodd" clipRule="evenodd" d="M23.3513 93C23.4544 92.9989 23.5495 93.0249 23.6242 93.0668C23.6984 93.1084 23.7417 93.1592 23.7637 93.2008C23.7848 93.2406 23.7891 93.2764 23.7847 93.3076C23.7803 93.3396 23.7641 93.383 23.7215 93.4291L23.7159 93.4351L16.9299 101.011L23.935 108.569C23.9349 108.568 23.9352 108.569 23.935 108.569C23.9927 108.631 24.0032 108.689 23.9992 108.729C23.9949 108.771 23.9695 108.839 23.8849 108.902C23.7973 108.967 23.6659 109.01 23.5207 108.998C23.3757 108.986 23.2655 108.924 23.2037 108.857L23.2029 108.856L16.0618 101.146C16.0127 101.091 16 101.041 16 101.006C16 100.97 16.013 100.919 16.0633 100.864L16.0703 100.856L22.969 93.1454C22.9693 93.1451 22.9695 93.1448 22.9698 93.1445C23.0028 93.1082 23.0512 93.0716 23.1161 93.0439C23.1816 93.0159 23.2577 92.9998 23.3377 93.0001L23.3513 93ZM15.325 101.822L22.4692 109.536C22.7117 109.798 23.0602 109.963 23.438 109.995C23.8159 110.026 24.192 109.921 24.4838 109.703C24.7756 109.484 24.9591 109.171 24.994 108.831C25.0288 108.491 24.9121 108.153 24.6696 107.89L18.2827 100.999L24.4553 94.1085C24.63 93.9198 24.741 93.69 24.7751 93.4463C24.8092 93.2026 24.765 92.9552 24.6478 92.7334C24.5305 92.5116 24.3451 92.3247 24.1135 92.1947C23.8819 92.0648 23.6137 91.9972 23.3408 92.0001C23.1273 91.9994 22.9164 92.0418 22.7235 92.1241C22.5307 92.2065 22.3608 92.3266 22.2263 92.4758L15.325 100.189C15.1149 100.419 15 100.708 15 101.006C15 101.303 15.1149 101.592 15.325 101.822Z" fill="black"/>
                    <defs>
                        <linearGradient id="paint0_linear_1987_24377" x1="19" y1="0" x2="19" y2="203" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0172FD"/>
                        <stop offset="1" stopColor="#018DFD"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_1987_24377" x1="19" y1="0" x2="19" y2="203" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0172FD"/>
                        <stop offset="1" stopColor="#018DFD"/>
                        </linearGradient>
                    </defs>
                    </svg>
                    </div>
                </div>
                <div className={showCanvas ? "canvas-bot-body op-100" : "canvas-bot-body op-0"} style={showCanvas ? {transitionDelay : "1.5s" , transitionDuration : "1s"} : {}}>
                    <div className="bottom-sec-memo">
                        </div>
                        <div className="content-container">

                    <div className="content" ref={chatContainerRef}>
                        <div className="con">

                        {
                            messages.map((e,i) => {
                                if(e.type === "bot") {
                                    return(
                                        <BotMessage key={i} text={e.text}/>
                                        )
                                    }else {
                                        return(
                                            <UserMessage key={i} text={e.text}/>
                                            )
                                        }
                                    })
                                }
                                </div>
                    </div>
                                </div>
                    <div className="messageing-area">
                        <input type="text" value={userMessage} onChange={(e) => {setUserMessage(e.target.value)}} placeholder="Type your message..."
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                if(userMessage !== '') {
                                    if(!loadBot) {
                                        addUserMessage();
                                        setLoadBot(1)
                                        GetBotMessages()
                                    }
                                }
                            }
                          }}
                        />
                    {!loadBot ? (<svg onClick={()=>{addUserMessage(); setLoadBot(1);GetBotMessages()}}  xmlns="http://www.w3.org/2000/svg" width="38" height="43" viewBox="0 0 38 43" fill="none">
                    <g clipPath="url(#clip0_1987_24375)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.1654 20.1429L20.9505 20.1114C21.4053 20.1094 21.7752 20.5238 21.7775 21.0361C21.7792 21.549 21.4123 21.9666 20.9575 21.9686L14.1753 22.0008L12.6051 30.7406C12.5434 31.0821 12.6569 31.4335 12.8986 31.6508C13.1403 31.8682 13.4694 31.9148 13.7513 31.7717L32.9289 21.9995C33.2225 21.8498 33.4123 21.5201 33.4135 21.1562C33.4147 20.7924 33.2271 20.4614 32.9336 20.3091L13.7746 10.3845C13.4933 10.2381 13.1631 10.2834 12.9202 10.4994C12.6773 10.7155 12.562 11.0675 12.6232 11.4097L14.1654 20.1429Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1987_24375">
                    <rect width="28.089" height="28.089" rx="8" transform="matrix(0.663503 0.748173 -0.663503 0.748173 18.6367 0)" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>) : (<div className='svg'><Spinner animation="grow" variant="primary" /></div>)}
                    </div>
                </div>
            </div>
            <span className={showCanvas ? "try-bot d-block-canvas-responsive op-0" : 'try-bot d-block-canvas-responsive'} style={showCanvas ? {} : {transitionDelay : "1s" , transitionDuration : "1s"}}>Try BOT</span>
            <div onClick={()=>{setShowCanvas(!showCanvas);addWelcomeMessage();}} className={showCanvas ? "Robot d-block-canvas-responsive go-top" :'Robot d-block-canvas-responsive'}>
                <img className={`${showCanvas ? "op-0" : "op-100"}`}  src={Robot2} alt="" />
                <img className={`${showCanvas ? "op-100" : "op-0"}`}  src={Robot} alt="" />
                <div className="Robot-Bg"></div>
            </div>
        </div>
    )
}

export default Landing ;
