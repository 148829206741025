import Landing from "./landing/Landing";

function App() {
  return (
    <div className="App">
      <Landing/>
    </div>
  );
}

export default App;
